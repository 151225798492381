import React from 'react'
import Instagram from '../assets/Instagram.png'
import Envelope from '../assets/Envelope.png'
import LinkedIn from '../assets/LinkedIn.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="bg-black text-white h-16 inset-x-0 bottom-0 mt-auto">
      <div className="lg:flex mx-4 py-4 lg:mx-12 lg:items-center sm:justify-center text-xs lg:text-base">
        <ul className="flex flex-wrap w-full justify-between sm:justify-between gap-4 sm:items-center">
          <li className="pt-2">
            <p>©2024 by Lustre&Laud.</p>
          </li>
          <li className=""></li>
          <div className="flex flex-wrap gap-2 pl-2 sm:ml-auto lg:space-x-4">
            <li>
              <Link to="https://www.instagram.com/lustreandlaud/">
                <img src={Instagram} alt="instagram-logo"></img>
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/company/creativestudio-n2/about/">
                <img src={LinkedIn} alt="linkedin-logo"></img>
              </Link>
            </li>
            <li>
              <Link to="mailto:naomi@lustreandlaud.com">
                <img src={Envelope} alt="envelope-logo"></img>
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </footer>
  );
}

export default Footer
