import React from 'react';

const Press = () => {
  const news = [
    {
      img: "https://picsum.photos/id/1015/1000/600/",
      date: "10.29.1998",
      title: "A Title",
      data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua.Integer eget aliquet nibh praesent tristique magna sit amet.Est pellentesque elit ullamcorper dignissim.In massa tempor nec feugiat nisl pretium fusce id.",
    },
    {
      img: "https://picsum.photos/id/1015/1000/600/",
      date: "10.29.1998",
      title: "A Title",
      data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua.Integer eget aliquet nibh praesent tristique magna sit amet.Est pellentesque elit ullamcorper dignissim.In massa tempor nec feugiat nisl pretium fusce id.",
    },
    {
      img: "https://picsum.photos/id/1015/1000/600/",
      date: "10.29.1998",
      title: "A Title",
      data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua.Integer eget aliquet nibh praesent tristique magna sit amet.Est pellentesque elit ullamcorper dignissim.In massa tempor nec feugiat nisl pretium fusce id.",
    },
  ];

  return (
    // <div className='flex flex-col items-center mt-6 sm:gap-2'>
    //   {news.map((item, index) => (
    //     <div key={index}>
    //       <div className='text-left'>
    //         <img src={item.img} alt={`News ${index + 1}`} />
    //         <figcaption className='text-left'>{item.date}</figcaption>
    //         <h1 className='text-xl'>{item.title}</h1>
    //         <p>{item.data}</p>
    //       </div>
    //     </div>
    //   ))}
    // </div>
    <div className="flex items-center justify-center min-h-screen">
      <p className="text-4xl sm:text-7xl italic pb-32 font-extrabold">
        Coming Soon...
      </p>
    </div>
  );
};

export default Press;